import Copy from '@nib-components/copy';
import {
  GenericSectionProps,
  IconWithBackgroundProps,
  SectionIconProps,
} from '../pages/life-cover-options/adviser-plans';
import React from 'react';
import styled from 'styled-components';
import { colorBlack, colorTrueGreen } from '@nib-components/theme';
import { Box } from '@nib/layout';
import Link from '@nib-components/link';

// icons
import GravestoneDollarIcon from '../img/life-and-living/icons/GravestoneDollarIcon.svg';
import HouseDollarIcon from '../img/life-and-living/icons/HouseDollarIcon.svg';
import GroupUnderRoofIcon from '../img/life-and-living/icons/GroupUnderRoofIcon.svg';
import HandGraduationHatIcon from '../img/life-and-living/icons/HandGraduationHatIcon.svg';
import ChessKnightDollarIcon from '../img/life-and-living/icons/ChessKnightDollarIcon.svg';
import TwoHandsDollarIcon from '../img/life-and-living/icons/TwoHandsDollarIcon.svg';
import GlobeIcon from '../img/life-and-living/icons/GlobeIcon.svg';
import ShieldTickIcon from '../img/life-and-living/icons/ShieldTickIcon.svg';
import DocumentMagnifyingGlassIcon from '../img/life-and-living/icons/DocumentMagnifyingGlassIcon.svg';
import ThumbUpDocumentIcon from '../img/life-and-living/icons/ThumbUpDocumentIcon.svg';
import DocumentPriceDownIcon from '../img/life-and-living/icons/DocumentPriceDownIcon.svg';
import HeartShakingHandsIcon from '../img/life-and-living/icons/HeartShakingHandsIcon.svg';
import HeartWithSurroundingArrowsIcon from '../img/life-and-living/icons/HeartWithSurroundingArrowsIcon.svg';
import GroupIcon from '../img/life-and-living/icons/GroupIcon.svg';
import PersonHoldingDollarIcon from '../img/life-and-living/icons/PersonHoldingDollarIcon.svg';
import ClipboardStethoscopeIcon from '../img/life-and-living/icons/ClipboardStethoscopeIcon.svg';
import PersonHoldingClipboardIcon from '../img/life-and-living/icons/PersonHoldingClipboardIcon.svg';
import PersonOnBarIcon from '../img/life-and-living/icons/PersonOnBarIcon.svg';
import BedAndSuitcaseIcon from '../img/life-and-living/icons/BedAndSuitcaseIcon.svg';
import PiggybackingIcon from '../img/life-and-living/icons/PiggybackingIcon.svg';
import PersonTickIcon from '../img/life-and-living/icons/PersonTickIcon.svg';
import FamilyHeartIcon from '../img/life-and-living/icons/FamilyHeartIcon.svg';
import HandWheelchairIcon from '../img/life-and-living/icons/HandWheelchairIcon.svg';
import HandDollarRecycleIcon from '../img/life-and-living/icons/HandDollarRecycleIcon.svg';
import CaregiverIcon from '../img/life-and-living/icons/CaregiverIcon.svg';
import HandsUpIcon from '../img/life-and-living/icons/HandsUpIcon.svg';
import ClipboardDollarIcon from '../img/life-and-living/icons/ClipboardDollarIcon.svg';
import FamilyFrontOfHouseIcon from '../img/life-and-living/icons/FamilyFrontOfHouseIcon.svg';
import FamilyShieldIcon from '../img/life-and-living/icons/FamilyShieldIcon.svg';
import DocumentHandMagnifyingGlassIcon from '../img/life-and-living/icons/DocumentHandMagnifyingGlassIcon.svg';
import WheelchairRampIcon from '../img/life-and-living/icons/WheelchairRampIcon.svg';
import DollarHomeIcon from '../img/life-and-living/icons/DollarHomeIcon.svg';
import PatientAndDoctorIcon from '../img/life-and-living/icons/PatientAndDoctorIcon.svg';
import { urlConstants } from '../constructs/constants';

const BigText = styled.div`
  font-weight: 900;
  color: ${colorTrueGreen};
  font-size: 100px;
`;

const BigParagraph = styled.p`
  font-size: 32px;
  color: ${colorBlack};
  line-height: 120%;
  margin: 0;
`;

const BoldBigParagraph = styled.b`
  color: ${colorTrueGreen};
  font-weight: 900;
`;

const Icon = styled.img`
  width: 100%;
  height: 100%;
`;

const SpeechBubble = styled.div`
  display: flex;
  padding: 24px;
  border-radius: 28px;
  gap: 24px;
  border-bottom-left-radius: 0px;
  background-color: #dae6d3;
  align-items: center;
  flex-direction: row;
`;

const listOfPolicies = [
  <Link href={urlConstants.ultimateLifeInsurancePolicyDocument} target="_blank">
    Ultimate Life Insurance
  </Link>,
  <Link href={urlConstants.ultimateTraumaInsuranceDocument} target="_blank">
    Ultimate Trauma Insurance
  </Link>,
  <Link href={urlConstants.ultimateTotalPermanentDisabilityInsuranceDocument} target="_blank">
    Ultimate Total & Permanent Disability Insurance
  </Link>,
  <Link href={urlConstants.ultimateIncomeProtectionInsuranceDocumentAgreedValue} target="_blank">
    Ultimate Income Protection Insurance - Agreed value
  </Link>,
  <Link href={urlConstants.ultimateIncomeProtectionInsuranceDocumentIndemnity} target="_blank">
    Ultimate Income Protection Insurance - Indemnity
  </Link>,
  <Link href={urlConstants.ultimateMortgageProtectionInsuranceDocument} target="_blank">
    Ultimate Mortgage Protection Insurance
  </Link>,
  <Link href={urlConstants.ultimateWaiverOfPremiumInsuranceDocument} target="_blank">
    Ultimate Waiver of Premium
  </Link>,
];

const UltimateLifeInsuranceSectionIconData: SectionIconProps[] = [
  {
    icon: GravestoneDollarIcon,
    text: 'Funeral expenses',
  },
  {
    icon: HouseDollarIcon,
    text: 'Mortgage repayments',
  },
  {
    icon: GroupUnderRoofIcon,
    text: 'Living expenses',
  },
  {
    icon: HandGraduationHatIcon,
    text: 'Future education',
  },
  {
    icon: ChessKnightDollarIcon,
    text: 'Future investments',
  },
  {
    icon: TwoHandsDollarIcon,
    text: 'Other essential expenses',
  },
];

const UltimateTraumaInsuranceSectionIconData: SectionIconProps[] = [
  {
    icon: ClipboardStethoscopeIcon,
    text: 'Medical expenses',
  },
  {
    icon: PersonHoldingClipboardIcon,
    text: 'Specialist treatment',
  },
  {
    icon: GroupUnderRoofIcon,
    text: 'Living expenses',
  },
  {
    icon: PersonOnBarIcon,
    text: 'Rehabilitation costs',
  },
  {
    icon: BedAndSuitcaseIcon,
    text: 'Travel & accommodation',
  },
  {
    icon: TwoHandsDollarIcon,
    text: 'Other essential expenses',
  },
];

const UltimateIncomeProtectionInsuranceSectionIconData: SectionIconProps[] = [
  {
    icon: GroupUnderRoofIcon,
    text: 'Living expenses',
  },
  {
    icon: ClipboardStethoscopeIcon,
    text: 'Medical expenses',
  },
  {
    icon: HandWheelchairIcon,
    text: 'Rahabilitation costs',
  },
  {
    icon: HandDollarRecycleIcon,
    text: 'Debit repayments',
  },
  {
    icon: CaregiverIcon,
    text: 'Caregiver costs',
  },
  {
    icon: TwoHandsDollarIcon,
    text: 'Other essential expenses',
  },
];

const UltimateMortgageProtectionInsuranceSectionIconData: SectionIconProps[] = [
  {
    icon: ClipboardDollarIcon,
    text: 'Cover mortgage repayments',
  },
  {
    icon: FamilyFrontOfHouseIcon,
    text: 'Safeguard your family home',
  },
  {
    icon: FamilyShieldIcon,
    text: "Protect your family's future",
  },
  {
    icon: DocumentHandMagnifyingGlassIcon,
    text: 'Minimise financial strain',
  },
  {
    icon: WheelchairRampIcon,
    text: 'Home accessibility adjustments',
  },
  {
    icon: TwoHandsDollarIcon,
    text: 'Other essential expenses',
  },
];

const UltimateTotalAndPermanentDisabilityInsuranceSectionIconData: SectionIconProps[] = [
  {
    icon: ClipboardStethoscopeIcon,
    text: 'Medical expenses',
  },
  {
    icon: PersonOnBarIcon,
    text: 'Rehabilitation costs',
  },
  {
    icon: WheelchairRampIcon,
    text: 'Home accessibility adjustments',
  },
  {
    icon: GroupUnderRoofIcon,
    text: 'Living expenses',
  },
  {
    icon: CaregiverIcon,
    text: 'Caregiver costs',
  },
  {
    icon: TwoHandsDollarIcon,
    text: 'Other essential expenses',
  },
];

const UltimateLifeInsuranceIconWithBackgroundData: IconWithBackgroundProps[] = [
  {
    heading: 'Worldwide cover^',
    description:
      'You’re covered no matter where you are - whether at home, travelling, or living overseas.',
    icon: GlobeIcon,
  },
  {
    heading: 'Future Upgrades',
    description:
      'If we make any improvements to our on-sale Ultimate Life Insurance cover in the future, that favourable change will automatically apply to you if that benefit exists in your policy.',
    icon: ShieldTickIcon,
  },
  {
    heading: 'Keeping Your Cover Current',
    description:
      'Ensure your cover keeps pace with inflation and your changing financial needs with options to increase your Life Cover on special life events and options to automatically increase your Life Cover every year.',
    icon: DocumentMagnifyingGlassIcon,
  },
  {
    heading: 'Guaranteed Wording',
    description:
      'Provides you with certainty over your cover, knowing that your benefits and terms are guaranteed, and won’t change unless requested by you, or in other limited circumstances.',
    icon: ThumbUpDocumentIcon,
  },
  {
    heading: 'Premium Discounts*',
    description:
      'Enjoy savings of 10-15% with our Multi-Cover Discount when you combine 2-4 eligible Ultimate Life & Living Insurance covers, like Trauma or Income Protection. Additionally, get a 10% Bundled Discount on nib’s Health Insurance when you take out Ultimate Life & Living Insurance cover alongside Ultimate Health/Max or Easy Health Insurance.',
    icon: DocumentPriceDownIcon,
  },
  {
    heading: 'Support Services',
    description:
      'The Counselling Benefit, and Financial & Legal Advice Benefit provide you with extra support by reimbursing costs for professional advice related to the Life Cover payout, and providing grief counselling for you or your family in the event of a death or a terminal diagnosis.',
    icon: HeartShakingHandsIcon,
  },
  {
    heading: 'Flexible Cover',
    description:
      'Allows you to put your cover on hold when your lifestyle or financial circumstances change.',
    icon: HeartWithSurroundingArrowsIcon,
  },
  {
    heading: 'Wide Eligibility Range',
    description:
      'Available for individuals aged 16 to 70 years old at entry, with no expiry age, ensuring protection at every stage of life.',
    icon: GroupIcon,
  },
];

const UltimateTraumaInsuranceIconWithBackgroundData: IconWithBackgroundProps[] = [
  {
    heading: 'Worldwide cover^',
    description:
      'You’re covered no matter where you are - whether at home, travelling, or living overseas.',
    icon: GlobeIcon,
  },
  {
    heading: 'Future Upgrades',
    description:
      'If we make any improvements to our on-sale Ultimate Life Insurance cover in the future, that favourable change will automatically apply to you if that benefit exists in your policy.',
    icon: ShieldTickIcon,
  },
  {
    heading: 'Keeping Your Cover Current',
    description:
      'Ensure your cover keeps pace with inflation and your changing financial needs with options to increase your Trauma cover on special life events and options to automatically increase your Trauma Cover every year.',
    icon: DocumentMagnifyingGlassIcon,
  },
  {
    heading: 'Built-in Cover for your Children',
    description:
      'With 49 conditions eligible for full payment and 16 conditions eligible for a partial payment, together with a newborn child benefit.',
    icon: PiggybackingIcon,
  },
  {
    heading: 'Wide Range of Conditions',
    description:
      'Offers cover for a broad range of defined medical conditions with the adult benefits available for 51 conditions for full payment and 18 conditions for partial payment.',
    icon: PersonTickIcon,
  },
  {
    heading: 'Guaranteed Wording',
    description:
      'Provides you with certainty over your cover, knowing that your benefits and terms are guaranteed, and won’t change unless requested by you, or in other limited circumstances.',
    icon: ThumbUpDocumentIcon,
  },
  {
    heading: 'Premium Discounts*',
    description:
      'Enjoy savings of 10-15% with our Multi-Cover Discount when you combine 2-4 eligible Ultimate Life & Living Insurance covers, like TPD or Income Protection. Additionally, get a 10% Bundled Discount on nib’s Health Insurance when you take out Ultimate Life & Living Insurance cover alongside Ultimate Health/Max or Easy Health Insurance.',
    icon: DocumentPriceDownIcon,
  },
  {
    heading: 'Support Services',
    description:
      'The Counselling Benefit, and Financial & Legal Advice Benefit provide you with extra support by reimbursing costs for professional advice related to the Full Trauma Benefit payout, and providing counselling for you or your family in the event you suffer from a full trauma condition.',
    icon: HeartShakingHandsIcon,
  },
  {
    heading: 'Flexible Cover',
    description:
      'Allows you to put your cover on hold when your lifestyle or financial circumstances change.',
    icon: HeartWithSurroundingArrowsIcon,
  },
];

const UltimateIncomeInsuranceIconWithBackgroundData: IconWithBackgroundProps[] = [
  {
    heading: 'Worldwide cover^',
    description:
      'You’re covered no matter where you are - whether at home, travelling, or living overseas.',
    icon: GlobeIcon,
  },
  {
    heading: 'Future Upgrades',
    description:
      'If we make any improvements to our on-sale Ultimate Income Protection Insurance cover in the future, that favourable change will automatically apply to you if that benefit exists in your policy.',
    icon: ShieldTickIcon,
  },
  {
    heading: 'Keeping Your Cover Current',
    description:
      'Ensure your cover keeps pace with inflation and your changing financial needs with the option to automatically increase your Income Protection Cover every year.',
    icon: DocumentMagnifyingGlassIcon,
  },
  {
    heading: 'Guaranteed Wording',
    description:
      'Provides you with certainty over your cover, knowing that your benefits and terms are guaranteed, and won’t change unless requested by you, or in other limited circumstances.',
    icon: ThumbUpDocumentIcon,
  },
  {
    heading: 'Premium Discounts*',
    description:
      'Enjoy savings of 10-15% with our Multi-Cover Discount when you combine 2-4 eligible Ultimate Life & Living Insurance covers, like Trauma or Income Protection. Additionally, get a 10% Bundled Discount on nib’s Health Insurance when you take out Ultimate Life & Living Insurance cover alongside Ultimate Health/Max or Easy Health Insurance.',
    icon: DocumentPriceDownIcon,
  },
  {
    heading: 'Support Services',
    description:
      'The Counselling Benefit provides you or your family with extra support by reimbursing costs for counselling services in the event you become totally disabled.',
    icon: HeartShakingHandsIcon,
  },
  {
    heading: 'Flexible Cover',
    description:
      'Allows you to put your cover on hold when your lifestyle or financial circumstances change.',
    icon: HeartWithSurroundingArrowsIcon,
  },
];

const UltimateHealthInsuranceIconWithBackgroundData: IconWithBackgroundProps[] = [
  {
    heading: 'Worldwide cover^',
    description:
      'You’re covered no matter where you are - whether at home, travelling, or living overseas.',
    icon: GlobeIcon,
  },
  {
    heading: 'Future Upgrades',
    description:
      'If we make any improvements to our on-sale nib Ultimate Mortgage Protection Insurance cover in the future, that favourable change will automatically apply to you if that benefit exists in your policy.',
    icon: ShieldTickIcon,
  },
  {
    heading: 'Keeping Your Cover Current',
    description:
      'Ensure your cover keeps pace with inflation and your changing financial needs with the option to automatically increase your Mortgage Protection Cover every year.',
    icon: DocumentMagnifyingGlassIcon,
  },
  {
    heading: 'Guaranteed Wording',
    description:
      'Provides you with certainty over your cover, knowing that your benefits and terms are guaranteed, and won’t change unless requested by you, or in other limited circumstances.',
    icon: ThumbUpDocumentIcon,
  },
  {
    heading: 'Premium Discounts*',
    description:
      'Enjoy savings of 10-15% with our Multi-Cover Discount when you combine 2-4 eligible Ultimate Life & Living Insurance covers, like Trauma or Income Protection. Additionally, get a 10% Bundled Discount on nib’s Health Insurance when you take out Ultimate Life & Living Insurance cover alongside Ultimate Health/Max or Easy Health Insurance.',
    icon: DocumentPriceDownIcon,
  },
  {
    heading: 'Support Services',
    description:
      'The Counselling Benefit provides you or your family with extra support in the event you become totally disabled.',
    icon: HeartShakingHandsIcon,
  },
  {
    heading: 'Flexible Cover',
    description:
      'Allows you to put your cover on hold when your lifestyle or financial circumstances change.',
    icon: HeartWithSurroundingArrowsIcon,
  },
];

const UltimateTotalAndPermanentDisabilityInsuranceIconWithBackgroundData: IconWithBackgroundProps[] =
  [
    {
      heading: 'Worldwide cover^',
      description:
        'You’re covered no matter where you are - whether at home, travelling, or living overseas.',
      icon: GlobeIcon,
    },
    {
      heading: 'Future Upgrades',
      description:
        'If we make any improvements to our on-sale Ultimate TBD insurance cover in the future, that favourable change will automatically apply to you if that benefit exists in your policy.',
      icon: ShieldTickIcon,
    },
    {
      heading: 'Keeping Your Cover Current',
      description:
        'Ensure your cover keeps pace with inflation and your changing financial needs with options to increase your Total & Permanent Disability Cover on special life events and options to automatically increase your Total & Permanent Disability Cover every year.',
      icon: DocumentMagnifyingGlassIcon,
    },
    {
      heading: 'Guaranteed Wording',
      description:
        'Provides you with certainty over your cover, knowing that your benefits and terms are guaranteed, and won’t change unless requested by you, or in other limited circumstances.',
      icon: ThumbUpDocumentIcon,
    },
    {
      heading: 'Premium Discounts*',
      description:
        'Enjoy savings of 10-15% with our Multi-Cover Discount when you combine 2-4 eligible Ultimate Life & Living Insurance covers, like Trauma or Income Protection. Additionally, get a 10% Bundled Discount on nib’s Health Insurance when you take out Ultimate Life & Living Insurance cover alongside Ultimate Health/Max or Easy Health Insurance.',
      icon: DocumentPriceDownIcon,
    },
    {
      heading: 'Support Services',
      description:
        'The Counselling Benefit, and Financial & Legal Advice Benefit provide you with extra support by reimbursing costs for professional advice related to the Total & Permanent Disability payout, and provide counselling for you or your family in the event you become totally and permanently disabled.',
      icon: HeartShakingHandsIcon,
    },
    {
      heading: 'Flexible Cover',
      description:
        'Allows you to put your cover on hold when your lifestyle or financial circumstances change.',
      icon: HeartWithSurroundingArrowsIcon,
    },
  ];

const adviserSections: Record<string, GenericSectionProps> = {
  ultimateLifeInsurance: {
    firstSection: {
      title: 'What is life insurance?',
      description: (
        <Copy measure={false}>
          Life doesn’t always go to plan. That’s why nib’s Ultimate Life Insurance provides a lump
          sum payment to protect your loved ones if you pass away or are diagnosed with a terminal
          illness. You can adapt your cover to suit your changing needs, ensuring your family is
          financially secure when they need it most.
        </Copy>
      ),
    },
    secondSection: {
      title: 'Why have life insurance?',
      description: (
        <Copy measure={false}>
          Ultimate Life Insurance protects what matters most - your loved ones’ future. The lump sum
          payment can help cover:
        </Copy>
      ),
      icons: UltimateLifeInsuranceSectionIconData,
    },
    thirdSection: {
      content: (
        <>
          <BigText>53%</BigText>
          <Box display="flex" flexDirection="column" gap={4} paddingVertical={4}>
            <BigParagraph>
              of Kiwis take out life insurance to{' '}
              <BoldBigParagraph>protect their family from financial consequences</BoldBigParagraph>{' '}
              after death or a health event
            </BigParagraph>
            <Copy color="trueGreen60" size="small">
              Source: Financial Services Council NZ, 2022
            </Copy>
          </Box>
          <Box minWidth="100px">
            <Icon src={PersonHoldingDollarIcon}></Icon>
          </Box>
        </>
      ),
    },
    fourthSection: {
      title: 'More info about Ultimate Life Insurance',
      description: (
        <Copy measure="false">
          Only the main features and benefits of our Ultimate Insurance plans are outlined here. For
          a full explanation of all benefits, benefit limits, and exclusions, view the Policy
          document for each plan:
        </Copy>
      ),
      buttons: {
        first: {
          title: 'Download the Ultimate Life Insurance Benefit Sheet',
          href: urlConstants.ultimateLifeInsuranceBenefitSheet,
        },
        second: {
          title: 'Download Ultimate Insurance Brochure',
          href: urlConstants.ultimateInsuranceDocument,
        },
      },
      list: listOfPolicies,
    },
    fifthSection: {
      title: 'Key Features',
      icons: UltimateLifeInsuranceIconWithBackgroundData,
    },
    sixthSection: {
      content: (
        <>
          <Copy measure="false">
            ^Personal exclusions may apply and payments are only made to an NZ bank account.
          </Copy>
          <Copy measure="false">
            *Talk to your adviser for the terms & conditions of this discount, including when we can
            change or remove the discount.{' '}
          </Copy>
        </>
      ),
    },
  },
  ultimateTraumaInsurance: {
    firstSection: {
      title: 'What is trauma insurance?',
      description: (
        <Copy measure={false}>
          When serious illness or injury strikes unexpectedly, nib’s Ultimate Trauma Insurance can
          provide financial support when it matters most. With a lump sum payment, you can focus on
          your recovery and spend time with loved ones instead of worrying about financial
          pressures.
        </Copy>
      ),
    },
    secondSection: {
      title: 'Why have trauma insurance?',
      description: (
        <Copy measure={false}>
          Ultimate Trauma Insurance is the safety net designed to help protect you if life throws
          you a serious health challenge. The lump sum payment can help to cover:
        </Copy>
      ),
      icons: UltimateTraumaInsuranceSectionIconData,
    },
    thirdSection: {
      content: (
        <>
          <Box minWidth="100px">
            <Icon src={FamilyHeartIcon}></Icon>
          </Box>
          <Box display="flex" gap={3} alignItems="center">
            <BigText>8</BigText>
            <BigParagraph>in</BigParagraph>
            <BigText>10</BigText>
          </Box>
          <Box display="flex" flexDirection="column" gap={4} paddingVertical={4}>
            <BigParagraph>
              <BoldBigParagraph>Kiwis would face financial difficulties</BoldBigParagraph> if faced
              with trauma or critical illness that left them unable to earn a living or look after
              their families
            </BigParagraph>
            <Copy color="trueGreen60" size="small">
              Source: Financial Services Council, Dec 2022
            </Copy>
          </Box>
        </>
      ),
    },
    fourthSection: {
      title: 'More info about Ultimate Trauma Insurance',
      description: (
        <Copy measure="false">
          Only the main features and benefits of our Ultimate Insurance plans are outlined here. For
          a full explanation of all benefits, benefit limits, and exclusions, view the Policy
          document for each plan:
        </Copy>
      ),
      buttons: {
        first: {
          title: 'Download the Ultimate Trauma Insurance Benefit Sheet',
          href: urlConstants.ultimateTraumaInsuranceBenefitSheet,
        },
        second: {
          title: 'Download Ultimate Insurance Brochure',
          href: urlConstants.ultimateInsuranceDocument,
        },
      },
      list: listOfPolicies,
    },
    fifthSection: {
      title: 'Key Features',
      icons: UltimateTraumaInsuranceIconWithBackgroundData,
    },
    sixthSection: {
      content: (
        <>
          <Copy measure="false">
            ^Personal exclusions may apply and payments are only made to an NZ bank account.
          </Copy>
          <Copy measure="false">
            *Talk to your adviser for the terms & conditions of this discount, including when we can
            change or remove the discount.{' '}
          </Copy>
        </>
      ),
    },
  },
  ultimateIncomeProtectionInsurance: {
    firstSection: {
      title: 'What is income protection insurance?',
      description: (
        <Copy measure={false}>
          If you are unable to work due to illness or injury, nib’s Ultimate Income Protection
          Insurance can help replace a significant portion of your income, enabling you to cover
          essential expenses and maintain your lifestyle. This financial support allows you to focus
          on recovery, not on your bills.
        </Copy>
      ),
    },
    secondSection: {
      title: 'Why have income protection insurance?',
      description: (
        <Copy measure={false}>
          Ultimate Income Protection supports with monthly payments that keep things moving. The
          monthly payments can help to cover:
        </Copy>
      ),
      icons: UltimateIncomeProtectionInsuranceSectionIconData,
    },
    thirdSection: {
      content: (
        <>
          <Box display="flex" gap={3} alignItems="center">
            <BigText>43%</BigText>
          </Box>
          <Box display="flex" flexDirection="column" gap={4} paddingVertical={4}>
            <BigParagraph>
              of New Zealanders would take out{' '}
              <BoldBigParagraph>Income Protection Insurance</BoldBigParagraph>
            </BigParagraph>
            <Copy color="trueGreen60" size="small">
              Source: Financial Services Council, Dec 2022{' '}
            </Copy>
          </Box>
          <SpeechBubble>
            <Box minWidth="48px">
              <Icon src={HandsUpIcon} />
            </Box>
            <Copy measure={false} color={colorTrueGreen}>
              “Because I worry about the financial consequences for myself/my family if I had a
              significant health event”
            </Copy>
          </SpeechBubble>
        </>
      ),
    },
    fourthSection: {
      title: 'More info about Ultimate Income Protection Insurance',
      description: (
        <Copy measure="false">
          Only the main features and benefits of our Ultimate Insurance plans are outlined here. For
          a full explanation of all benefits, benefit limits, and exclusions, view the Policy
          document for each plan:
        </Copy>
      ),
      buttons: {
        first: {
          title: 'Download the Ultimate Income Protection Insurance Benefit Sheet',
          href: urlConstants.ultimateIncomeProtectionInsuranceBenefitSheet,
        },
        second: {
          title: 'Download Ultimate Insurance Brochure',
          href: urlConstants.ultimateInsuranceDocument,
        },
      },
      list: listOfPolicies,
    },
    fifthSection: {
      title: 'Key Features',
      icons: UltimateIncomeInsuranceIconWithBackgroundData,
    },
    sixthSection: {
      content: (
        <>
          <Copy measure="false">
            ^Personal exclusions may apply and payments are only made to an NZ bank account.
          </Copy>
          <Copy measure="false">
            *Talk to your adviser for the terms & conditions of this discount, including when we can
            change or remove the discount.{' '}
          </Copy>
        </>
      ),
    },
  },
  ultimateMortgageProtectionInsurance: {
    firstSection: {
      title: 'What is mortgage protection insurance?',
      description: (
        <Copy measure={false}>
          If you are unable to work due to illness or injury, nib’s Ultimate Mortgage Protection
          Insurance helps ensure your mortgage or rest payments are covered during tough times.
        </Copy>
      ),
    },
    secondSection: {
      title: 'Why have mortgage protection insurance?',
      description: (
        <Copy measure={false}>
          Ultimate Mortgage Protection Insurance eases mortgage stress by supporting you during
          challenging times. Choose between up to 115% of your mortgage repayments or 45% of your
          gross income, with no ‘other income’ offsets to determine your cover. The monthly payments
          can help:
        </Copy>
      ),
      icons: UltimateMortgageProtectionInsuranceSectionIconData,
    },
    thirdSection: {
      content: (
        <>
          <BigText>24%</BigText>
          <Box display="flex" flexDirection="column" gap={4} paddingVertical={4}>
            <BigParagraph>
              spend over{' '}
              <BoldBigParagraph>half their income on mortgage repayments</BoldBigParagraph> each
              month and over half (59%) of New Zealanders{' '}
              <BoldBigParagraph>are suffering from mortgage stress</BoldBigParagraph>
            </BigParagraph>
            <Copy color="trueGreen60" size="small">
              Source: Fam NZ, November 2024{' '}
            </Copy>
          </Box>
          <Box minWidth="160px">
            <Icon src={DollarHomeIcon}></Icon>
          </Box>
        </>
      ),
    },
    fourthSection: {
      title: 'More info about Ultimate Mortgage Protection Insurance',
      description: (
        <Copy measure="false">
          Only the main features and benefits of our Ultimate Insurance plans are outlined here. For
          a full explanation of all benefits, benefit limits, and exclusions, view the Policy
          document for each plan:
        </Copy>
      ),
      buttons: {
        first: {
          title: 'Download the Ultimate Mortgage Protection Insurance Benefit Sheet',
          href: urlConstants.ultimateMortgageProtectionInsuranceBenefitSheet,
        },
        second: {
          title: 'Download Ultimate Insurance Brochure',
          href: urlConstants.ultimateInsuranceDocument,
        },
      },
      list: listOfPolicies,
    },
    fifthSection: {
      title: 'Key Features',
      icons: UltimateHealthInsuranceIconWithBackgroundData,
    },
    sixthSection: {
      content: (
        <>
          <Copy measure="false">
            ^Personal exclusions may apply and payments are only made to an NZ bank account.
          </Copy>
          <Copy measure="false">
            *Talk to your adviser for the terms & conditions of this discount, including when we can
            change or remove the discount.{' '}
          </Copy>
        </>
      ),
    },
  },
  ultimateTotalAndPermanentDisabilityInsurance: {
    firstSection: {
      title: 'What is total & permanent disability insurance?',
      description: (
        <Copy measure={false}>
          A permanent disability can disrupt your life, but nib’s Ultimate Total & Permanent
          Disability Insurance can help support your financial wellbeing with a lump sum payment, so
          you can rebuild your future with less financial strain.
        </Copy>
      ),
    },
    secondSection: {
      title: 'Why have total & permanent disability insurance?',
      description: (
        <Copy measure={false}>
          Ultimate TPD Insurance provides a lump sum payment if you’re diagnosed with an injury or
          illness that leaves you unable to work. This payment can help cover:
        </Copy>
      ),
      icons: UltimateTotalAndPermanentDisabilityInsuranceSectionIconData,
    },
    thirdSection: {
      content: (
        <>
          <Box minWidth="120px">
            <Icon src={PatientAndDoctorIcon}></Icon>
          </Box>
          <Box display="flex" gap={3} alignItems="center">
            <BigText>8</BigText>
            <BigParagraph>in</BigParagraph>
            <BigText>10</BigText>
          </Box>
          <Box display="flex" flexDirection="column" gap={4} paddingVertical={4}>
            <BigParagraph>
              Kiwis would struggle to <BoldBigParagraph>support their families</BoldBigParagraph>{' '}
              after a permanent disability
            </BigParagraph>
            <Copy color="trueGreen60" size="small">
              Source: Financial Services Council, Dec 2022{' '}
            </Copy>
          </Box>
        </>
      ),
    },
    fourthSection: {
      title: 'More info about Ultimate Total & Permanent Disability Insurance',
      description: (
        <Copy measure="false">
          Only the main features and benefits of our Ultimate Insurance plans are outlined here. For
          a full explanation of all benefits, benefit limits, and exclusions, view the Policy
          document for each plan:
        </Copy>
      ),
      buttons: {
        first: {
          title: 'Download the Ultimate Total & Permanent Disability Insurance Benefit Sheet',
          href: urlConstants.ultimateTotalPermanentDisabilityInsuranceBenefitSheet,
        },
        second: {
          title: 'Download Ultimate Insurance Brochure',
          href: urlConstants.ultimateInsuranceDocument,
        },
      },
      list: listOfPolicies,
    },
    fifthSection: {
      title: 'Key Features',
      icons: UltimateTotalAndPermanentDisabilityInsuranceIconWithBackgroundData,
    },
    sixthSection: {
      content: (
        <>
          <Copy measure="false">
            ^Personal exclusions may apply and payments are only made to an NZ bank account.
          </Copy>
          <Copy measure="false">
            *Talk to your adviser for the terms & conditions of this discount, including when we can
            change or remove the discount.{' '}
          </Copy>
        </>
      ),
    },
  },
};

export default adviserSections;
