import {
  colorSageGreen60,
  colorTrueGreen,
  colorTrueGreen80,
  colorWarmWhite,
} from '@nib-components/theme';
import React from 'react';
import styled from 'styled-components';

interface GridTableProps {
  columnTitles: JSX.Element[];
  data: JSX.Element[][];
}

const Table = styled.div`
  display: grid;
  border: 1px solid ${colorTrueGreen80};
  border-collapse: collapse;
  color: ${colorTrueGreen};
`;

const Cell = styled.div<{
  isHeader?: boolean;
  isFirstColumn?: boolean;
  isLastColumn?: boolean;
  isAlternatingColumn?: boolean;
}>`
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 20px;
  border: 0.5px solid ${colorTrueGreen80};
  border-left: ${({ isFirstColumn }) =>
    isFirstColumn ? `0.5px solid ${colorTrueGreen80}` : '0.5px solid white'};
  border-right: ${({ isLastColumn }) =>
    isLastColumn ? `0.5px solid ${colorTrueGreen80}` : '0.5px solid white'};
  color: ${({ isHeader }) => (isHeader ? 'white' : colorTrueGreen)};
  background-color: ${({ isHeader, isFirstColumn, isAlternatingColumn }) =>
    isHeader
      ? colorTrueGreen
      : isFirstColumn
      ? '#C2D6B5'
      : isAlternatingColumn
      ? colorWarmWhite
      : colorSageGreen60};
  font-weight: ${({ isHeader }) => (isHeader ? 'bold' : 'normal')};
  text-align: center;
`;

const GridTable = ({ columnTitles, data }: GridTableProps): JSX.Element => {
  const columnCount = columnTitles.length;

  return (
    <Table style={{ gridTemplateColumns: `repeat(${columnCount}, 1fr)` }}>
      {/* Column titles */}
      {columnTitles.map((title, index) => (
        <Cell key={`col-title-${index}`} isHeader={true}>
          {title}
        </Cell>
      ))}

      {/* Row titles and data */}
      {data.map((row, rowIndex) => (
        <React.Fragment key={`row-${rowIndex}`}>
          {row.map((cellData, colIndex) => (
            <Cell
              key={`cell-${rowIndex}-${colIndex}`}
              isFirstColumn={colIndex === 0}
              isLastColumn={colIndex === row.length - 1}
              isAlternatingColumn={colIndex % 2 === 0}
            >
              {cellData}
            </Cell>
          ))}
        </React.Fragment>
      ))}
    </Table>
  );
};

export default GridTable;
