import React, { useState } from 'react';

import Helmet from 'react-helmet';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

import { Section, Stack, Box, Column, Columns } from '@nib/layout';

import { PrimaryButton } from '@nib-components/button';
import Copy, { Bold, ListItem, UnorderedList } from '@nib-components/copy';
import Heading from '@nib-components/heading';
import Container from '@nib-components/container';
import { Tabs, TabList, TabPanel } from '@nib-components/tabs';
import Link from '@nib-components/link';
import {
  colorWhite,
  colorTrueGreen20,
  colorTrueGreen60,
  colorSageGreen60,
} from '@nib-components/theme';

import CallBackModal from '../../components/CallBackModal';
import HeroPanel from '../../components/HeroPanel';
import Layout from '../../components/Layout';
import { HeroPanelCopy, Tab } from '../../components/styledComponents';
import UtilityButtons from '../../components/UtilityButtons';
import VerticalFeaturePanel from '../../components/VerticalFeaturePanel';
import { UtilityLinkModel } from '../../constructs/models';
import metrics from '../../metrics';
import adviserSections from '../../content/life-and-living-adviser-plans';

import CoupleTalkingToAdviserImage from '../../img/life-and-living/couple-talking-to-an-adviser.jpeg';
import CoupleTalkingToAdviserInOfficeImage from '../../img/life-and-living/the-benefits-of-using an adviser.jpg';

// custom icons
import HeartWithSurroundingArrowsIcon from '../../img/life-and-living/icons/HeartWithSurroundingArrowsIcon.svg';
import TickCircleIcon from '../../img/life-and-living/icons/TickCircleIcon.svg';
import ShieldMuscleIcon from '../../img/life-and-living/icons/ShieldMuscleIcon.svg';
import GridTable from '../../components/GridTable';
import ReinforcedShieldIcon from '../../img/life-and-living/icons/ReinforcedShieldIcon.svg';
import PuzzleIcon from '../../img/life-and-living/icons/PuzzleIcon.svg';
import ClipboardTickIcon from '../../img/life-and-living/icons/ClipboardTickIcon.svg';
import ReinforcedShieldWithSurroundingArrowsIcon from '../../img/life-and-living/icons/ReinforcedShieldWithSurroundingArrowsIcon.svg';
import { urlConstants } from '../../constructs/constants';

const GettingCoverSection = () => (
  <Section role="section" background="sageGreen60">
    <Stack space={4}>
      <Box textAlign="center">
        <Heading color="trueGreen" size={{ sm: 4, md: 3, lg: 2 }} component="h2">
          Get cover through an adviser
        </Heading>
      </Box>
      <Copy measure={false}>
        You can buy your life and living insurance through an independent licensed financial advice
        provider. Their financial advisers or nominated representatives have specialist knowledge to
        help you identify and prioritise your needs and choose cover that suits your circumstances
        and budget.
      </Copy>
      <Copy measure={false}>
        Our comprehensive life and living insurance products are offered through advisers. You can
        enjoy all the benefits of being an nib member, plus have additional support from your
        adviser with claims or any future changes to your life and living cover.
      </Copy>
    </Stack>
  </Section>
);

const adviserBenefitStepFeatures = [
  {
    heading: 'Specialist advice',
    content: () =>
      "An adviser can help identify what's essential for you, explain the different options available and find the right insurance plan to suit your needs.",
    image: CoupleTalkingToAdviserInOfficeImage,
  },
  {
    heading: 'Ongoing support',
    content: () =>
      'Your needs can change over time. A good adviser will check in regularly to make sure your plan still meets your needs.',
    image: CoupleTalkingToAdviserInOfficeImage,
  },
  {
    heading: 'There to help at claim time',
    content: () =>
      'If you have to make a claim, your adviser will work with you and nib to get your claim assessed to help get you back on your feet.',
    image: CoupleTalkingToAdviserInOfficeImage,
  },
  {
    heading: 'Financial guidance',
    content: () =>
      'An adviser can give you advice about life and living insurance and many can also advise other financial services as well, such as health insurance, mortgages, investing and KiwiSaver.',
    image: CoupleTalkingToAdviserInOfficeImage,
  },
];

const adviserBenefitStepDescription = {
  title: 'The benefits of using an adviser',
  subTitle: null,
};

const BenefitSection = (): JSX.Element => (
  <Section role="section">
    <VerticalFeaturePanel
      description={adviserBenefitStepDescription}
      features={adviserBenefitStepFeatures}
      useAnimation={false}
    />
  </Section>
);

const FancyBoxTrueGreen = styled.div`
  display: flex;
  background-color: ${colorTrueGreen20};
  padding: 36px;
  border-top-left-radius: 40px;
  border-bottom-right-radius: 40px;
  gap: 16px;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
`;

const FancyBoxYellow = styled.div`
  display: flex;
  background-color: #feefd8;
  padding: 36px;
  border-top-left-radius: 40px;
  border-bottom-right-radius: 40px;
  gap: 16px;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;

  ${breakpoint('lg')`
    flex-direction: row;
    gap: 0px;
  `};
`;

const Icon = styled.img`
  width: 100%;
  height: 100%;
`;

const Line = styled.div`
  width: 40px;
  border: 2px solid;
`;

export interface SectionIconProps {
  icon: any;
  text: string;
}

const SectionIcon = ({ icon, text }: SectionIconProps): JSX.Element => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={4}
      alignItems="center"
      color="trueGreen"
      maxWidth={'160px'}
    >
      <Box width="80px" height="80px">
        <Icon src={icon} />
      </Box>
      <Bold align="center">{text}</Bold>
      <Line />
    </Box>
  );
};

export interface IconWithBackgroundProps {
  icon: any;
  heading: string;
  description: string;
}

const IconWithBackground = ({
  icon,
  heading,
  description,
}: IconWithBackgroundProps): JSX.Element => {
  return (
    <Box display="flex" gap={2} padding={3}>
      <Box
        padding={3}
        minWidth="60px"
        minHeight="60px"
        width="60px"
        height="60px"
        background="trueGreen"
        borderRadius="full"
      >
        <Icon src={icon} />
      </Box>
      <Box>
        <Heading component="h4">{heading}</Heading>
        <Copy size="small">{description}</Copy>
      </Box>
    </Box>
  );
};

const FullWidthLine = styled.div`
  width: 100%;
  border: 1px solid ${colorTrueGreen60};
`;

export interface GenericSectionProps {
  firstSection: {
    title: string;
    description: JSX.Element;
  };
  secondSection: {
    title: string;
    description: JSX.Element;
    icons: SectionIconProps[];
  };
  thirdSection: {
    content: JSX.Element;
  };
  fourthSection: {
    title: string;
    description: JSX.Element;
    buttons: {
      first: {
        title: string;
        href: string;
      };
      second: {
        title: string;
        href: string;
      };
    };
    list: typeof Link[];
  };
  fifthSection: {
    title: string;
    icons: IconWithBackgroundProps[];
  };
  sixthSection: {
    content: JSX.Element;
  };
}

const GenericSection = (props: GenericSectionProps): JSX.Element => {
  return (
    <Section>
      <Container>
        <Box display="flex" flexDirection="column" gap={6}>
          <Box display="flex" flexDirection="column" gap={4}>
            <Heading component="h3" color="trueGreen">
              {props.firstSection.title}
            </Heading>
            {props.firstSection.description}
          </Box>
          <Box display="flex" flexDirection="column" gap={4}>
            <Heading component="h3" color="trueGreen">
              {props.secondSection.title}
            </Heading>
            {props.secondSection.description}
          </Box>
          <FancyBoxTrueGreen>
            {props.secondSection.icons.map((iconData, index) => (
              <SectionIcon key={index} {...iconData} />
            ))}
          </FancyBoxTrueGreen>
          <Box display="flex" flexDirection="column" gap={4}>
            <FullWidthLine />
            <Box
              display="flex"
              paddingHorizontal={6}
              gap={5}
              alignItems="center"
              flexDirection={{ xs: 'column', lg: 'row' }}
            >
              {props.thirdSection.content}
            </Box>
            <FullWidthLine />
          </Box>
          <Box display="flex" flexDirection="column" gap={4}>
            <Heading component="h3" color="trueGreen">
              {props.fourthSection.title}
            </Heading>
            <Box display="flex" flexDirection={{ xs: 'column', lg: 'row' }} gap={4}>
              <PrimaryButton href={props.fourthSection.buttons.first.href} target="_blank">
                {props.fourthSection.buttons.first.title}
              </PrimaryButton>
              <PrimaryButton href={props.fourthSection.buttons.second.href} target="_blank">
                {props.fourthSection.buttons.second.title}
              </PrimaryButton>
            </Box>
            {props.fourthSection.description}
            <UnorderedList>
              {props.fourthSection.list.map((link, index) => (
                <ListItem key={index}>{link}</ListItem>
              ))}
            </UnorderedList>
          </Box>
          <Heading component="h3">{props.fifthSection.title}</Heading>
          <FancyBoxYellow>
            <Columns wrap={true} space={4} collapseBelow="xl">
              {props.fifthSection.icons.map((iconData, index) => (
                <Column width="1/3" key={index}>
                  <IconWithBackground key={index} {...iconData} />
                </Column>
              ))}
            </Columns>
          </FancyBoxYellow>
          <Box display="flex" flexDirection="column" gap={4}>
            {props.sixthSection.content}
          </Box>
        </Box>
      </Container>
    </Section>
  );
};

const listOfPolicies = [
  <Link href={urlConstants.ultimateLifeInsurancePolicyDocument} target="_blank">
    Ultimate Life Insurance
  </Link>,
  <Link href={urlConstants.ultimateTraumaInsuranceDocument} target="_blank">
    Ultimate Trauma Insurance
  </Link>,
  <Link href={urlConstants.ultimateTotalPermanentDisabilityInsuranceDocument} target="_blank">
    Ultimate Total & Permanent Disability Insurance
  </Link>,
  <Link href={urlConstants.ultimateIncomeProtectionInsuranceDocumentAgreedValue} target="_blank">
    Ultimate Income Protection Insurance - Agreed value
  </Link>,
  <Link href={urlConstants.ultimateIncomeProtectionInsuranceDocumentIndemnity} target="_blank">
    Ultimate Income Protection Insurance - Indemnity
  </Link>,
  <Link href={urlConstants.ultimateMortgageProtectionInsuranceDocument} target="_blank">
    Ultimate Mortgage Protection Insurance
  </Link>,
  <Link href={urlConstants.ultimateWaiverOfPremiumInsuranceDocument} target="_blank">
    Ultimate Waiver of Premium
  </Link>,
];

const WaiverOfPremiumSection = (): JSX.Element => {
  const columnTitles = [<Copy>Benefit</Copy>, <Copy>Description</Copy>, <Copy>Cover</Copy>];
  const data = [
    [
      <Box display="flex" gap={3} alignItems="center" flexDirection={{ xs: 'column', lg: 'row' }}>
        <Box width="28px" height="28px">
          <Icon src={TickCircleIcon}></Icon>
        </Box>
        <Bold align={{ xs: 'center', lg: 'start' }}>Total Disability Waiver Benefit</Bold>
      </Box>,
      <Copy>
        Covers all of your Ultimate Life & Living premiums if you become totally disabled due to
        illness or injury.
      </Copy>,
      <Copy>Waives 100% of your nib Ultimate Life & Living premiums.</Copy>,
    ],
    [
      <Box display="flex" gap={3} alignItems="center" flexDirection={{ xs: 'column', lg: 'row' }}>
        <Box width="28px" height="28px">
          <Icon src={TickCircleIcon}></Icon>
        </Box>
        <Bold align={{ xs: 'center', lg: 'start' }}>Partial Disability Waiver Benefit</Bold>
      </Box>,
      <Copy>
        Covers all of your Ultimate Life & Living premiums if you become partially disabled due to
        illness or injury.
      </Copy>,
      <Copy>Waives 100% of your nib Ultimate Life & Living premiums.</Copy>,
    ],
    [
      <Box display="flex" gap={3} alignItems="center" flexDirection={{ xs: 'column', lg: 'row' }}>
        <Box width="28px" height="28px">
          <Icon src={TickCircleIcon}></Icon>
        </Box>
        <Bold align={{ xs: 'center', lg: 'start' }}>Recurring Claim Benefit</Bold>
      </Box>,
      <Copy>
        Waives the wait period if you become totally or partially disabled again from the same or a
        related illness or injury within 12 months of the previous claim ending. illness or injury.
      </Copy>,
      <Copy>
        Resumes your Total or Partial Disability Waiver Benefit (as applicable) for the rest of your
        benefit payment period without requiring the wait period to be met again.
      </Copy>,
    ],
  ];

  return (
    <Section>
      <Container>
        <Box display="flex" flexDirection="column" gap={6}>
          <Box display="flex" flexDirection="column" gap={4}>
            <Heading component="h3" color="trueGreen">
              What is waiver of premium?
            </Heading>
            <Copy measure={false}>
              Life is unpredictable, but your insurance doesn’t have to be. nib’s Waiver of Premium
              can pay your Ultimate Life & Living premiums if you become disabled, ensuring your
              cover remains active and easing the financial burden of managing your insurance costs.
            </Copy>
          </Box>
          <Box display="flex" flexDirection="column" gap={4}>
            <Heading component="h3" color="trueGreen">
              Benefits of waiver of premium?
            </Heading>
            <GridTable columnTitles={columnTitles} data={data} />
          </Box>
          <Box display="flex" flexDirection="column" gap={4}>
            <Heading component="h3" color="trueGreen">
              Wait & Benefit Period Options{' '}
            </Heading>
            <Copy measure={false}>
              Our nib Ultimate Waiver of Premium allows you to choose from various Wait and Benefit
              Period Options depending on your occupation class.
            </Copy>
            <Box width="80%">
              <Columns collapseBelow="lg" space={4}>
                <Column>
                  <Heading component="h4" color="trueGreen">
                    Benefit Periods
                  </Heading>
                  <Stack space={4}>
                    <UnorderedList listType="none" indent={0} inset={0}>
                      <ListItem>2 years</ListItem>
                      <ListItem>5 years</ListItem>
                      <ListItem>To age 65</ListItem>
                      <ListItem>To age 70 (occupation classes 1 & 2 only)</ListItem>
                    </UnorderedList>
                    <Copy>
                      Occupation class 5 can only select 2 years or 5 years benefit period
                    </Copy>
                  </Stack>
                </Column>
                <Column>
                  <Heading component="h4" color="trueGreen">
                    Wait Periods
                  </Heading>
                  <Stack space={4}>
                    <UnorderedList listType="none" indent={0} inset={0}>
                      <ListItem>4 weeks</ListItem>
                      <ListItem>8 weeks</ListItem>
                      <ListItem>13 weeks</ListItem>
                      <ListItem>26 weeks</ListItem>
                      <ListItem>52 weeks</ListItem>
                      <ListItem>104 weeks (occupation class 1 only)</ListItem>
                    </UnorderedList>
                    <Copy>Occupation class 5 can only select 13, 26, or 52 weeks</Copy>
                  </Stack>
                </Column>
              </Columns>
            </Box>
          </Box>
          <Box display="flex" flexDirection="column" gap={4}>
            <Heading component="h3" color="trueGreen">
              More info about Ultimate Waiver of Premium{' '}
            </Heading>
            <Box display="flex" flexDirection={{ xs: 'column', lg: 'row' }} gap={4}>
              <PrimaryButton
                href={urlConstants.ultimateWaiverOfPremiumInsuranceBenefitSheet}
                target="_blank"
              >
                Download Ultimate Waiver of Premium Benefit Sheet
              </PrimaryButton>
              <PrimaryButton href={urlConstants.ultimateInsuranceDocument} target="_blank">
                Download Ultimate Insurance Brochure
              </PrimaryButton>
            </Box>
            <Copy>
              Only the main features and benefits of our Ultimate Insurance plans are outlined here.
              For a full explanation of all benefits, benefit limits, and exclusions, view the
              Policy document for each plan:
            </Copy>
            <UnorderedList>
              {listOfPolicies.map((link, index) => (
                <ListItem key={index}>{link}</ListItem>
              ))}
            </UnorderedList>
          </Box>
          <Heading component="h3">Key features</Heading>
          <FancyBoxYellow>
            <Columns wrap={true} space={4} collapseBelow="xl">
              <Column width="1/2">
                <IconWithBackground
                  icon={ShieldMuscleIcon}
                  heading="Strengthen your Protection"
                  description="Combine Waiver of Premium with Life, Trauma, Total & Permanent Disability (TPD), Income Protection, or Mortgage Protection cover to help ensure your policy remains active if you become totally disabled or partially disabled."
                />
              </Column>
              <Column width="1/2">
                <IconWithBackground
                  icon={HeartWithSurroundingArrowsIcon}
                  heading="Flexibility To Tailor Your Cover"
                  description="Add Ultimate Waiver of Protection to your policy to keep your protection in place during times of disability or incapacity, You can customise the wait and benefit periods to create a solution that works perfectly for your unique needs and financial situation."
                />
              </Column>
            </Columns>
          </FancyBoxYellow>
        </Box>
      </Container>
    </Section>
  );
};

const TabLabel = styled.div`
  ${breakpoint('lg')`
    width: 200px;
    white-space: normal;
    overflow-wrap: normal;
  `};
`;

const StyledTabList = styled(TabList)`
  li:not(.react-tabs__tab--selected) {
    color: ${colorWhite};
  }
`;

const PlanSection = (): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <CallBackModal variant="Adviser" isOpen={isOpen} setOpen={setIsOpen} />
      <Section role="section" background={'sageGreen60'}>
        <Stack space={4}>
          <Box textAlign="center" background="sneezy">
            <Heading color="trueGreen" size={{ sm: 4, md: 3, lg: 2 }} component="h2">
              Our plans through an adviser
            </Heading>
          </Box>
          <Copy measure={false} align="center">
            Your adviser will be able to help find the right cover for you, your partner and family.
            With the wide range of cover options available, every person can get the cover they
            need.
          </Copy>
          <Box
            display="flex"
            flexDirection={{ xs: 'column', md: 'row' }}
            gap={4}
            justifyContent="center"
            alignItems="center"
          >
            <Box>
              <PrimaryButton href={urlConstants.ultimateInsuranceDocument} target="_blank">
                Download Ultimate Insurance Brochure
              </PrimaryButton>
            </Box>
            <Box>
              <PrimaryButton onClick={() => setIsOpen(true)}>Talk to an adviser</PrimaryButton>
            </Box>
          </Box>
        </Stack>
      </Section>
      <Tabs defaultIndex={0}>
        <StyledTabList center={true} variation="container" background="sageGreen60">
          <Tab>
            <TabLabel>Ultimate Life Insurance</TabLabel>
          </Tab>
          <Tab>
            <TabLabel>Ultimate Trauma Insurance</TabLabel>
          </Tab>
          <Tab>
            <TabLabel>Ultimate Income Protection Insurance</TabLabel>
          </Tab>
          <Tab>
            <TabLabel>Ultimate Mortgage Protection Insurance</TabLabel>
          </Tab>
          <Tab>
            <TabLabel>Ultimate Total & Permanent Disability Insurance</TabLabel>
          </Tab>
          <Tab>
            <TabLabel>Ultimate Waiver of Premium</TabLabel>
          </Tab>
        </StyledTabList>
        <TabPanel>
          <GenericSection {...adviserSections['ultimateLifeInsurance']} />
        </TabPanel>
        <TabPanel>
          <GenericSection {...adviserSections['ultimateTraumaInsurance']} />
        </TabPanel>
        <TabPanel>
          <GenericSection {...adviserSections['ultimateIncomeProtectionInsurance']} />
        </TabPanel>
        <TabPanel>
          <GenericSection {...adviserSections['ultimateMortgageProtectionInsurance']} />
        </TabPanel>
        <TabPanel>
          <GenericSection {...adviserSections['ultimateTotalAndPermanentDisabilityInsurance']} />
        </TabPanel>
        <TabPanel>
          <WaiverOfPremiumSection />
        </TabPanel>
      </Tabs>
    </>
  );
};

const ContactSection = (): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Section role="section" background="trueGreen">
      <CallBackModal variant="Adviser" isOpen={isOpen} setOpen={setIsOpen} />
      <Stack space={4}>
        <Box textAlign="center">
          <Heading size={{ sm: 4, md: 3, lg: 2 }} component="h2" color="white">
            Talk to an adviser
          </Heading>
        </Box>
        <Copy align="center" color="white" measure={false}>
          An adviser can talk with you about the financial risks of ill health or disablement and
          the various insurance options available to you.
        </Copy>
        <Copy align="center" color="white" measure={false}>
          Call your adviser today or if you don’t have one, simply fill in the contact form and an
          independent financial advice provider will get in touch.
        </Copy>
        <Box textAlign="center">
          <PrimaryButton onClick={() => setIsOpen(true)}>Talk to an adviser</PrimaryButton>
        </Box>
      </Stack>
    </Section>
  );
};

const LeftFancyBox = styled(FancyBoxTrueGreen)`
  background-color: ${colorSageGreen60};
`;

const RightFancyBox = styled(FancyBoxTrueGreen)`
  background-color: #01573c;
`;

const LargeBold = styled(Bold)`
  font-size: 32px;
`;

const LargeCopy = styled(Copy)`
  font-size: 32px;
`;

const DiscountSection = (): JSX.Element => {
  return (
    <Section background={'trueGreen'}>
      <Box display="flex" flexDirection="column" gap={6} color="white">
        <Heading component="h2" size={{ xs: 2 }} fontType="serif">
          Save with nib's discounts
        </Heading>
        <Columns space={5} collapseBelow="lg">
          <Column>
            <Box color="trueGreen">
              <LeftFancyBox>
                <Box display="flex" width="100%" flexDirection="column" gap={4}>
                  <Heading component="h3" size={{ xs: 2 }} fontType="serif">
                    Life & Living Multi-cover Discount
                  </Heading>
                  <Copy>
                    Combine eligible covers under your Ultimate Life & Living Insurance policy to
                    receive a discount on your premiums*.
                    <br />
                    <br />
                    <b>Eligible covers are:</b> Life, Trauma, Total & Permanent Disability, Income
                    Protection or Mortgage Protection cover.
                  </Copy>
                  <Bold transform="uppercase" size="large">
                    The more you combine, the greater the discount:
                  </Bold>
                  <Columns collapseBelow="lg" space={4}>
                    <Column>
                      <Box display="flex">
                        <Box width="34px">
                          <Icon src={ReinforcedShieldIcon} />
                        </Box>
                        <Box width="34px">
                          <Icon src={ReinforcedShieldIcon} />
                        </Box>
                      </Box>
                      <Bold transform="uppercase" size="large">
                        Two types of covers
                      </Bold>
                      <LargeBold transform="uppercase" size="large">
                        10% OFF
                      </LargeBold>
                    </Column>
                    <Column>
                      <Box display="flex">
                        <Box width="34px">
                          <Icon src={ReinforcedShieldIcon} />
                        </Box>
                        <Box width="34px">
                          <Icon src={ReinforcedShieldIcon} />
                        </Box>
                        <Box width="34px">
                          <Icon src={ReinforcedShieldIcon} />
                        </Box>
                      </Box>
                      <Bold transform="uppercase" size="large">
                        Two types of covers
                      </Bold>
                      <LargeBold transform="uppercase" size="large">
                        12.5% OFF
                      </LargeBold>
                    </Column>
                    <Column>
                      <Box display="flex">
                        <Box width="34px">
                          <Icon src={ReinforcedShieldIcon} />
                        </Box>
                        <Box width="34px">
                          <Icon src={ReinforcedShieldIcon} />
                        </Box>
                        <Box width="34px">
                          <Icon src={ReinforcedShieldIcon} />
                        </Box>
                        <Box width="34px">
                          <Icon src={ReinforcedShieldIcon} />
                        </Box>
                      </Box>
                      <Bold transform="uppercase" size="large">
                        Three types of covers
                      </Bold>
                      <LargeBold transform="uppercase" size="large">
                        15% OFF
                      </LargeBold>
                    </Column>
                  </Columns>
                  <Copy>
                    Build a tailored plan with the covers that matter most to you. Your premiums are
                    based on the covers you select helping you save while meeting your specific
                    needs.
                  </Copy>
                </Box>
              </LeftFancyBox>
            </Box>
          </Column>
          <Column>
            <RightFancyBox>
              <Box display="flex" width="100%" flexDirection="column" gap={3}>
                <Heading component="h3" size={{ xs: 2 }} fontType="serif">
                  Health and Life & Living Bundled Discount
                </Heading>
                <LargeCopy transform="uppercase">
                  Get <b>10% off</b> your health insurance premiums
                </LargeCopy>
                <Copy>
                  when you bundle any new eligible Health Insurance and Ultimate Life & Living
                  insurance*.
                </Copy>
                <Box display="flex" gap={4} flexDirection="column">
                  <Box display="flex" gap={4} alignItems="center">
                    <Box width="75px">
                      <Icon src={PuzzleIcon} />
                    </Box>
                    <Box>
                      <Heading component="h4">Comprehensive cover:</Heading>
                      <Copy>
                        Get peace of mind knowing you have protection for both your health and
                        financial wellbeing.
                      </Copy>
                    </Box>
                  </Box>
                  <Box display="flex" gap={4} alignItems="center">
                    <Box width="75px">
                      <Icon src={ClipboardTickIcon} />
                    </Box>
                    <Box>
                      <Heading component="h4">Easy and cost-effective:</Heading>
                      <Copy>Apply for Health and Life & Living cover together and save money.</Copy>
                    </Box>
                  </Box>
                  <Box display="flex" gap={4} alignItems="center">
                    <Box width="75px">
                      <Icon src={ReinforcedShieldWithSurroundingArrowsIcon} />
                    </Box>
                    <Box>
                      <Heading component="h4">Convenience:</Heading>
                      <Copy>Save time and effort by having your covers with a single insurer.</Copy>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </RightFancyBox>
          </Column>
        </Columns>
        <Copy align="center" measure={false}>
          *Terms and conditions apply. Discounts do not apply to the policy fee component of the
          premiums.
        </Copy>
      </Box>
    </Section>
  );
};

const NavigationSection = (): JSX.Element => {
  const utilityLinks: UtilityLinkModel[] = [
    {
      subtitle: 'Need an adviser?',
      title: 'Find one here',
      url: '#adviser-contact',
    },
    {
      subtitle: 'NZ health insurance?',
      title: 'Learn how it works',
      url: '/health-insurance',
    },
    {
      subtitle: 'NZ Life & Living insurance?',
      title: 'Learn how it works',
      url: '/life-cover-options',
    },
    {
      subtitle: 'Want to know more?',
      title: 'Learn about nib',
      url: '/about-nib',
    },
  ];
  return (
    <Section role="section" background="warmWhite60" padding={4}>
      <UtilityButtons buttons={utilityLinks} flex={true} />
    </Section>
  );
};

const title = 'Adviser Plan Comparison';
const description =
  'Get life cover insurance through an adviser and find the right cover to suit your personal needs.';

const AdviserPlansPage = (): JSX.Element => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const schema = {
    '@context': 'https://schema.org',
    '@type': 'WebPage',
    '@id': 'WebPage',
    identifier: 'https://www.nib.co.nz/life-cover-options/adviser-plans',
    url: 'https://www.nib.co.nz/life-cover-options/adviser-plans',
    name: 'Talk to an adviser about nib life and living insurance | nib',
    description:
      'Get life insurance through an adviser and find the right cover to suit your personal needs.',
    isPartOf: 'https://www.nib.co.nz',
  };
  const JSONschema = JSON.stringify(schema);

  return (
    <Layout>
      <CallBackModal variant="Adviser" isOpen={isOpen} setOpen={setIsOpen} />
      <Helmet>
        <title>{title} | nib</title>
        <meta name="description" content={description} />
        <script type="application/ld+json">{JSONschema}</script>
      </Helmet>
      <HeroPanel
        images={{
          desktop: CoupleTalkingToAdviserImage,
          desktop2x: CoupleTalkingToAdviserImage,
          mobile: CoupleTalkingToAdviserImage,
          mobile2x: CoupleTalkingToAdviserImage,
          tablet: CoupleTalkingToAdviserImage,
          tablet2x: CoupleTalkingToAdviserImage,
        }}
        title="Life and living insurance through an adviser"
        variation="condensed"
      >
        <HeroPanelCopy>
          An adviser works with you to find an insurance plan to suit your needs.{' '}
        </HeroPanelCopy>
        <PrimaryButton onClick={() => setIsOpen(true)}>Talk to an adviser</PrimaryButton>
      </HeroPanel>
      <GettingCoverSection />
      <BenefitSection />
      <a id="adviser-contact" />
      <ContactSection />
      <PlanSection />
      <DiscountSection />
      <NavigationSection />
    </Layout>
  );
};

export default metrics({ pageName: 'adviser-plans' })(AdviserPlansPage);
